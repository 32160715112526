var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Action = void 0;
var Action;
(function (Action) {
  Action["UPDATE"] = "APP::MARKETING_EXTERNAL_ACTIVITY_TOP_BAR::UPDATE";
  Action["BUTTON_CLICK"] = "APP::MARKETING_EXTERNAL_ACTIVITY_TOP_BAR::BUTTONS::BUTTON::CLICK";
  Action["BUTTON_UPDATE"] = "APP::MARKETING_EXTERNAL_ACTIVITY_TOP_BAR::BUTTONS::BUTTON::UPDATE";
})(Action = exports.Action || (exports.Action = {}));
export default exports;
export const __esModule = exports.__esModule;
const _Action = exports.Action;
export { _Action as Action };